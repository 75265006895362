import React from "react"

import Img from "gatsby-image"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"

export default function PaymentCard({
  imageFluid,
  title,
  paymentType,
  url,
  button,
}) {
  return (
    <Col lg={3} md={4} sm={6} className="my-3">
      <Card className="payment-card p-4">
        <Card.Img as={Img} variant="top" fluid={imageFluid} alt={title} />
        <Card.Body className="px-0 pb-0">
          <Card.Text>{paymentType}</Card.Text>
          {button && (
            <Button
              variant="outline-danger"
              as="a"
              href={url}
              target="_blank"
              rel="noreferrer noopener"
              block
            >
              {title}
            </Button>
          )}
        </Card.Body>
      </Card>
    </Col>
  )
}
PaymentCard.defaultProps = {
  imageFluid: "",
  title: "",
  paymentType: "",
  url: "",
  button: false,
}
