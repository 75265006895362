import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
// import BackgroundImage from "gatsby-background-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import { AiOutlineFileWord } from "react-icons/ai"

import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"
import CabinetLoginModal from "../components/global/CabinetLoginModal"
// import ContentfulReachContent from "../../components/global/ContentfulReachContent"
// import Col from "react-bootstrap/Col"
// import Card from "react-bootstrap/Card"

import PaymentCard from "../components/CardPayments"

const OplataPage = ({
  data: { payments, terminaly, nal, onlines, step1, step2 },
}) => {
  // const gradientImage = [
  //   image.fluid,
  //   `radial-gradient(at 50% 100%, rgba(181, 45, 168, 0.6), rgba(15, 1, 94, 0.75))`,
  // ].reverse()

  return (
    <Layout>
      <SEO
        title="Оплатити Інтернет Нового Телекому"
        description="Способи оплати Інтернету і Телебаченні від Нового Телекому."
        image=""
        url="/sposoby-oplaty/"
      />
      <div className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              {/* <li className="breadcrumb-item">
                <Link to="/shop/">Допомога</Link>
              </li> */}
              <li className="breadcrumb-item active" aria-current="page">
                Способи оплати
              </li>
            </ol>
          </nav>
          <h1 className="gradient-text">Способи оплати</h1>
          <div className="hero-slogan mb-4">
            Як зручно оплатити Інтернет і Телебачення від Нового Телекому з
            мінімальною комісією.
          </div>
        </Container>
      </div>
      <Container className="py-5">
        <h2>В особистому кабінеті</h2>
        <Row>
          <p>
            Найбільш зручним і швидким способом оплати Інтернету є поповнення
            балансу онлайн в особистому кабінеті Нового Телекому.
          </p>
          <ul>
            <li>
              Ваш логін буде одразу підтягнуто, достатньо вказати лише суму
              поповнення балансу.
            </li>
            <li>
              Майже миттєво кошти будуть нараховані на баланс, не потрібно
              очікувати кілька банківських днів поки гроші зайдуть, і
              бухгалтерія ідентифікує платежі.
            </li>
          </ul>
          <h3>Як поповнити баланс в особистому кабінеті</h3>
          <ol>
            <li>Зайдіть в особистий кабінет - введіть логін та пароль.</li>
            <div className="mt-2 mb-3">
              <CabinetLoginModal />
            </div>
            <li>Перейдіть до розділу - «Поповнення рахунку».</li>
            <Img fixed={step1.fixed} alt="оплата крок 1" />
            <li>Вкажіть суму, яку вам необхідно оплатити.</li>
            <li>
              Оберіть платіжну систему та натисніть - «Далі». Алгоритм дій
              залишається однаковим при виборі будь-якої з платіжних систем -
              Ukrpays, EasySoft чи Portmone.
            </li>
            <Img fixed={step2.fixed} alt="оплата крок 2" />
            <li>
              Вкажіть дані банківської карти, здійсніть оплату, і майже миттєво
              побачите кошти на балансі особистого кабінету.
            </li>
          </ol>
          <p>Сплачуйте швидко та зручно!</p>
        </Row>
      </Container>
      <Container className="payment-providers my-5">
        <h2>Як сплатити онлайн?</h2>
        <Row>
          {onlines.nodes.map(method => (
            <PaymentCard
              key={method.id}
              imageFluid={method.logo.fluid}
              title={method.title}
              paymentType={method.paymentType.type}
              url={method.url}
              button={true}
            />
          ))}
        </Row>
      </Container>
      <Container className="payment-providers my-5">
        <h2>В яких терміналах можна оплатити?</h2>
        <Row>
          {terminaly.nodes.map(method => (
            <PaymentCard
              key={method.id}
              imageFluid={method.logo.fluid}
              title={method.title}
              paymentType={method.paymentType.type}
              url={method.url}
            />
          ))}
        </Row>
      </Container>
      <Container>
        {nal.nodes.map(method => (
          <div key={method.id} className="my-5">
            <h2>Як оплатити по квитанції в банк</h2>
            <Img fixed={method.logo.fixed} alt={method.title} />
            <div
              dangerouslySetInnerHTML={{
                __html: method.instruction.childMarkdownRemark.html,
              }}
            ></div>
            <Button
              variant="outline-danger"
              as="a"
              href={method.url}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-3"
            >
              Скачати Квитанцію .doc <AiOutlineFileWord />
            </Button>
            {/* <Button
              variant="outline-danger"
              as="a"
              href={method.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Скачати Квитанцію .pdf <AiOutlineFilePdf />
            </Button> */}
          </div>
        ))}
      </Container>
    </Layout>
  )
}

export default OplataPage

export const query = graphql`
  {
    image: contentfulAsset(id: { eq: "a07d7cd2-b7ff-5920-abbc-0bff1d69e913" }) {
      fluid(maxWidth: 1920) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    step1: contentfulAsset(
      node_locale: { eq: "uk" }
      file: { fileName: { eq: "oplata-step-01.png" } }
    ) {
      fixed(width: 300, resizingBehavior: SCALE) {
        ...GatsbyContentfulFixed_withWebp
      }
    }
    step2: contentfulAsset(
      node_locale: { eq: "uk" }
      file: { fileName: { eq: "oplata-step-02.png" } }
    ) {
      fixed(width: 300, resizingBehavior: SCALE) {
        ...GatsbyContentfulFixed_withWebp
      }
    }
    payments: allContentfulPaymentMethod(
      filter: { node_locale: { eq: "uk" } }
    ) {
      nodes {
        id
        title
        url
        paymentType {
          type
        }
        instruction {
          childMarkdownRemark {
            html
            htmlAst
          }
        }
        logo {
          fixed(width: 300, resizingBehavior: SCALE) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(resizingBehavior: PAD, maxWidth: 300, maxHeight: 200) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    nal: allContentfulPaymentMethod(
      filter: {
        paymentType: {
          type: { eq: "Готівкою в Банку" }
          node_locale: { eq: "uk" }
        }
      }
    ) {
      nodes {
        id
        title
        url
        paymentType {
          type
        }
        instruction {
          childMarkdownRemark {
            html
            htmlAst
          }
        }
        logo {
          fixed(width: 300, resizingBehavior: SCALE) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(resizingBehavior: PAD, maxWidth: 300, maxHeight: 200) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    terminaly: allContentfulPaymentMethod(
      filter: {
        paymentType: {
          type: { eq: "Оплата в терміналах" }
          node_locale: { eq: "uk" }
        }
      }
    ) {
      nodes {
        id
        title
        url
        paymentType {
          type
        }
        instruction {
          childMarkdownRemark {
            html
            htmlAst
          }
        }
        logo {
          fixed(width: 350, resizingBehavior: SCALE) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(resizingBehavior: PAD, maxWidth: 300, maxHeight: 200) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    onlines: allContentfulPaymentMethod(
      filter: {
        paymentType: {
          type: { eq: "Онлайн оплата" }
          node_locale: { eq: "uk" }
        }
      }
    ) {
      nodes {
        id
        title
        url
        paymentType {
          type
        }
        instruction {
          childMarkdownRemark {
            html
            htmlAst
          }
        }
        logo {
          fixed(width: 350, resizingBehavior: SCALE) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(resizingBehavior: PAD, maxWidth: 300, maxHeight: 200) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
